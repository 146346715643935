


import { Component, Prop, Vue } from 'vue-property-decorator';
import Person from '@/_modules/contacts/components/person/person.vue';
import { Action } from 'vuex-class';

const DEFAULT_AVATAR = require('@/assets/images/no-avatar-60x60.svg');

@Component({
  components: {Person},
})
export default class ContactListItem extends Vue {

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number }) => void;

  @Prop({ type: Object })
  public readonly contact: any;

  // TODO: it is unused. Check if it is needed and remove if not
  @Prop({ type: Boolean, default: false })
  public readonly isLast: boolean;

  public isVisible: boolean = false;

  public mounted(): void {
    if (this.$refs.myself) {
      try {
        const element: HTMLDivElement = this.$refs.myself as HTMLDivElement;
        const observer = new IntersectionObserver((entries) => {
          if (entries && entries[0] && entries[0].isIntersecting) {
            this.isVisible = true;
            observer.disconnect();
          }
        }, {
          threshold: 0.1
        });
        observer.observe(element);
      } catch {
        this.isVisible = true;
      }
    }
  }

  public get contactIdParam(): number {
    return (this.$route.params.contact_id && parseInt(this.$route.params.contact_id, 10)) || null;
  }

  public get avatar(): string {
    return this.contact.photo_url || DEFAULT_AVATAR;
  }

  public showContact(): void {
    this.openContactCard({ contactId: this.contact.id });
    this.$router.push({
      name: 'promo-page-contacts-contact',
      params: { contact_id: this.contact.id },
      query: this.$route.query,
    }).catch(e => e);
  }

  public toggleFavorite(): void {
    if (!this.contact) {
      return;
    }
    if (this.contact.is_favorite) {
      this.$store.dispatch('contactsStore/removeFavContact', { contactId: this.contact.id });
    } else {
      this.$store.dispatch('contactsStore/addFavContact', { contactId: this.contact.id });
    }
  }
}
