import { render, staticRenderFns } from "./ew-avatar-company.html?vue&type=template&id=537df034&scoped=true&"
import script from "./ew-avatar-company.vue?vue&type=script&lang=ts&"
export * from "./ew-avatar-company.vue?vue&type=script&lang=ts&"
import style0 from "./ew-avatar-company.scss?vue&type=style&index=0&id=537df034&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "537df034",
  null
  
)

export default component.exports