


import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {TTab} from '@/_ui/tabs/types/tabs.type';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {Location} from 'vue-router';
import {TContactTag} from '@/_types/contact-tag.type';
import {ContactsFilterType, TContactsFilter} from '@/_modules/contacts/types/contacts-filter.type';
import {TContact} from '@/_types/contact.type';
import {TGetContactCountriesParams} from '@/_api/contacts/contacts.api';
import searchBar from '@/_components/search-bar/search-bar.vue';
import contacts from '@/_modules/contacts/components/contacts/contacts.vue';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import CompaniesListItem from '@/_modules/promo-hall/components/companies-list-item/companies-list-item.vue';
import SegmentControl from '@/_ui/segment-control/segment-control.vue';
import IconEwStar from '@/_modules/icons/components/icon-ew-star.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import PageTitle from '@/_components/page-title/page-title.vue';

type TLocalContactTag = {
  tag: TContactTag;
  isSelected: boolean;
};

@Component({
  components: {
    searchBar,
    PageTitle,
    SegmentControl,
    IconEwStar,
    contacts,
    IconArrowLeft,
    CompaniesListItem,
    SimplePopup,
  }
})
export default class ContactsViewer extends Vue {

  @Getter('_eventStore/eventContactTags') eventContactTags: TContactTag[];
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('promoStore/promoPageByExternalId') companyByExternalId: (externalId: string) => TPromoPage;
  @Getter('contactsStore/contactCountries') contactCountries: string[];

  @Action('contactsStore/getContactCountries') getContactCountries: (payload: TGetContactCountriesParams) => void;

  public isTagsSelectorPopupVisible: boolean = false;
  public eventContactTagsLocal: TLocalContactTag[] = [];
  public searchString: string = '';
  public chosenContactCountry: string = '';

  public tabList: TTab[] = [
    {
      title: this.$t('navigation.all'),
      isActive: this.$route.query.type === 'all',
      rightTopBadge: null,
      index: 0,
    },
    {
      title: this.$t('navigation.my'),
      isActive: this.$route.query.type === 'my',
      rightTopBadge: null,
      index: 1,
    }
  ];

  @Watch('eventContactTags', {immediate: true})
  private onEventContactTagsChange(): void {
    this.initEventContactTagsLocal();
  }

  @Watch('chosenContactCountry')
  private onChosenContactCountryChange(): void {
    this.updateSearchParams();
  }

  public get isFilterSearchDisabled(): boolean {
    return this.queryType === 'my';
  }

  public get queryType(): 'all' | 'my' {
    return this.$route.query.type === 'my' ? 'my' : 'all';
  }

  public get querySearch(): string {
    return ('' + (this.$route.query.search || '')).trim();
  }

  public get queryTagIds(): number[] {
    if (typeof this.$route.query.tags === 'string') {
      return [parseInt(this.$route.query.tags, 10)];
    } else if (Array.isArray(this.$route.query.tags)) {
      return (this.$route.query.tags as string[]).map(item => parseInt(item, 10));
    }
    return [];
  }

  public get menuLocationTypeAll(): Location {
    return {
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        type: undefined,
      }),
    };
  }

  public get menuLocationTypeMy(): Location {
    return {
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        type: 'my',
      }),
    };
  }

  public get isPromoPageNavigationInvisible(): boolean {
    return this.filter.type === ContactsFilterType.BOOTH_VIEWERS;
  }

  public get filter(): TContactsFilter {
    let tagIds: number[] = [];
    if (typeof this.$route.query.tags === 'string') {
      tagIds = [parseInt(this.$route.query.tags, 10)];
    } else if (Array.isArray(this.$route.query.tags)) {
      tagIds = (this.$route.query.tags as string[]).map(item => parseInt(item, 10));
    }

    let type: ContactsFilterType;
    switch (this.$route.query.type) {
      case ContactsFilterType.BOOTH_VIEWERS:
      case ContactsFilterType.MY:
        type = this.$route.query.type;
        break;
      default:
        type = ContactsFilterType.ALL;
    }

    return {
      type: type,
      search: this.querySearch,
      country: this.chosenContactCountry.trim(),
      tagIds: tagIds,
    };
  }

  public get contactCountriesPrepared(): string[] {
    return this.contactCountries
      .filter(x => x)
      .sort();
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public mounted(): void {
    this.initContactsSearchBarTabs();
    this.initContactCountries();
    this.searchString = this.querySearch;
  }

  public initContactCountries(): void {
    if (this.$route.query && this.$route.query.country) {
      this.chosenContactCountry = (this.$route.query.country as string).trim();
    }
    this.getContactCountries({eventId: this.eventId});
  }

  public initContactsSearchBarTabs(): void {
    if (this.$route.query.type === ContactsFilterType.BOOTH_VIEWERS) {
      return;
    }
    this.queryType === 'my' ? this.onActiveTabUpdate(this.tabList[1], 1) : this.onActiveTabUpdate(this.tabList[0], 0);
  }

  public get companyForBoothViewers(): TPromoPage {
    const isBoothViewersParamPresent: boolean = this.$route.query.type === ContactsFilterType.BOOTH_VIEWERS;
    const boothExternalId: string = this.$route.query.boothExternalId as string;
    if (!isBoothViewersParamPresent || (!boothExternalId && boothExternalId !== '0')) {
      return null;
    }
    return this.companyByExternalId(boothExternalId);
  }

  public onFilterClick(): void {
    if (this.isFilterSearchDisabled) {
      this.isTagsSelectorPopupVisible = false;
      return;
    }
    this.isTagsSelectorPopupVisible = !this.isTagsSelectorPopupVisible;
  }

  public closeContactTagsSelectorPopup(): void {
    this.isTagsSelectorPopupVisible = false;
  }

  public toggleContactTagSelection(localContactTag: TLocalContactTag): void {
    localContactTag.isSelected = !localContactTag.isSelected;
    this.updateSearchParams();
  }

  public updateSearchParams(): void {
    const tagIds = this.eventContactTagsLocal
      .filter(item => item.isSelected)
      .map(item => item.tag.id);

    const searchValue: string = this.searchString ? this.searchString.trim() : '';
    this.$router.push({
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        tags: tagIds.length > 0 ? tagIds : undefined,
        search: searchValue || undefined,
        country: this.chosenContactCountry.trim() || undefined,
      }),
    }).catch(/* ignore */);
  }

  public clearSelectedContactTags(): void {
    this.$router.push({
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        tags: undefined,
        country: undefined,
      }),
    });
    this.initEventContactTagsLocal();
    this.closeContactTagsSelectorPopup();
    this.chosenContactCountry = '';
  }

  public isContactCountryOptionSelected(countryName: string): boolean {
    return countryName === this.chosenContactCountry;
  }

  public onSearchClick(value: string): void {
    if (this.isFilterSearchDisabled) {
      return;
    }
    this.searchString = (value || '').trim();
    if (this.searchString !== this.querySearch) {
      this.updateSearchParams();
    }
  }

  private initEventContactTagsLocal(): void {
    const queryTagIds = this.queryTagIds;
    this.eventContactTagsLocal = (this.eventContactTags || []).map(tag => {
      return {
        tag,
        isSelected: queryTagIds.findIndex(id => id === tag.id) >= 0
      };
    });
  }

  public onActiveTabUpdate(tab: TTab, index: number): void {
    this.tabList.forEach((item, idx) => {
      item.isActive = idx === index;
    });

    this.redirectByActiveTab(this.tabList[index]);
  }

  public redirectByActiveTab(tab: TTab): void {
    this.$router.push(tab.index < 1 ? this.menuLocationTypeAll : this.menuLocationTypeMy).catch(() => { /* ignore */
    });
  }

  private proceedToOwnBooth(): void {
    if (!this.myself || !this.myself.promopage_external_id) {
      return;
    }
    try {
      this.$router.push({
        name: 'promo-page-events-company',
        params: {
          external_id: this.myself.promopage_external_id
        }
      });
    } catch (e) {
      /* ignore */
    }
  }
}
