


import {Component, Prop, Vue} from 'vue-property-decorator';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';

const DEFAULT_AVATAR = require('@/assets/images/no-avatar-company-60x60.svg');

@Component
export default class EwAvatarCompany extends Vue {

  @Prop({type: Object, default: null})
  public readonly company: TPromoPage;

  @Prop({type: String, default: ''})
  public src: string;

  @Prop({type: Number, default: 48, })
  public width: number;

  public get avatarImageStyles(): any {
    const width = this.width;
    return {
      width: `${width}px`,
      height: `${width}px`,
      'min-width': `${width}px`,
      'min-height': `${width}px`,
      'max-width': `${width}px`,
      'max-height': `${width}px`,
      'background-color': this.backgroundColorValue,
      'background-image': this.backgroundImageValue
    };
  }

  public get backgroundColorValue(): string {

    if (!this.companyInitialLetter) {
      return 'transparent';
    }

    const companyExternalId: string = this.company ? this.company.external_id : '0';

    const companyName: string = ((this.company ? this.company.title : '') || '').trim();
    const sourceString = companyName || companyExternalId;

    let hash = 0;
    for (let i = 0; i < sourceString.length; i++) {
      hash = sourceString.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;

    return 'hsl(' + h + ', 100%, 75%)';
  }

  public get backgroundImageValue(): string {
    if (!this.src && !this.companyLogoUrl) {
      if (!this.companyInitialLetter) {
        return 'url(' + DEFAULT_AVATAR + ')';
      }
      return 'url(' + DEFAULT_AVATAR + ')'; // '' if need companyInitialLetter
    }
    return 'url(' + (this.src || this.companyLogoUrl) + ')';
  }

  public get companyLogoUrl(): string {
    if (!this.company || !this.company.logo_url) {
      return '';
    }
    return this.company.logo_url;
  }

  public get companyInitialLetter(): string {
    if (!this.company) {
      return '';
    }

    return (this.company.title || '').trim().substr(0, 1);
  }

  public get style(): string {
    return `width: ${this.width}px; height: ${this.width}px;`;
  }

}
