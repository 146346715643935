import { render, staticRenderFns } from "./contacts.html?vue&type=template&id=518d7eae&scoped=true&"
import script from "./contacts.vue?vue&type=script&lang=ts&"
export * from "./contacts.vue?vue&type=script&lang=ts&"
import style0 from "./contacts.scss?vue&type=style&index=0&id=518d7eae&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518d7eae",
  null
  
)

export default component.exports